html,body                       {min-height: 100%;}
.page                           {padding-bottom: 4rem;
    &-loop                      {display: flex;flex-direction: column;padding: .4rem .4rem 0 .4rem;}
    &-item                      {display: flex;margin-bottom: .6rem;
        .pic                    {width: 1rem;height: 1rem;border-radius: 50%;position: relative;overflow: hidden;box-shadow: .2rem .1rem .4rem #e0e0e0;z-index: 2;
            img                 {position: absolute;top: 50%;left: 50%;transform: translate3d(-50%, -50%, 0);width: 100%;}
        }
        .info                   {margin-top: .4rem;border-radius: .2rem;
            .content            {width: 100%;border-radius: .4rem;box-sizing: border-box;padding: .4rem .5rem .6rem;box-shadow: .2rem .1rem .4rem #e0e0e0;
                .question       {.fs(14);color: #333;font-weight: 600;line-height: .6rem;}
                .answer         {
                    a           {display: block;line-height: .6rem;margin-top: .4rem;background: #f9f9f9;padding: .16rem .4rem;border-radius: .4rem;
                        &.active{background: #5ec2b5;color: #FFF;}
                    }
                }
            }
        }
        &.left                  {
            .info               {margin-left: .6rem;width: 7.2rem;
                .content        {background: #FFF;position: relative;
                    &:before    {content: '';display: block;background: url(../../../assets/images/answer_left.png) no-repeat center / 100%;width: .8rem;height: .8rem;position: absolute;left: -.4rem;top: -.18rem;z-index: 1;}
                    .answer     {position: relative;z-index: 2;}
                }
            }
        }
        &.right                 {margin-left: 1.2rem;justify-content: flex-end;
            .info               {margin-right: .4rem;
                .content        {background: #5ec2b5;border-radius: .66rem;padding: 0 .5rem;height: 1rem;line-height: 1rem;position: relative;
                    &:before    {content: '';display: block;background: url(../../../assets/images/answer_right.png) no-repeat center / 100%;width: .8rem;height: .8rem;position: absolute;right: -.2rem;top: -.18rem;z-index: 1;}
                    .answer     {color: #FFF;.fs(16);position: relative;z-index: 2;}
                }
            }
        }
    }
    &-time                      {display: flex;justify-content: center;padding: .4rem 0;
        span                    {color: #FFF;background: #d8d8d8;display: inline-block;padding: .1rem .4rem;border-radius: 1rem;}
    }
}