html,
body {
  min-height: 100%;
}
.page {
  padding-bottom: 4rem;
}
.page-loop {
  display: flex;
  flex-direction: column;
  padding: .4rem .4rem 0 .4rem;
}
.page-item {
  display: flex;
  margin-bottom: .6rem;
}
.page-item .pic {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  box-shadow: .2rem .1rem .4rem #e0e0e0;
  z-index: 2;
}
.page-item .pic img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 100%;
}
.page-item .info {
  margin-top: .4rem;
  border-radius: .2rem;
}
.page-item .info .content {
  width: 100%;
  border-radius: .4rem;
  box-sizing: border-box;
  padding: .4rem .5rem .6rem;
  box-shadow: .2rem .1rem .4rem #e0e0e0;
}
.page-item .info .content .question {
  font-size: 0.37333333rem;
  color: #333;
  font-weight: 600;
  line-height: .6rem;
}
.page-item .info .content .answer a {
  display: block;
  line-height: .6rem;
  margin-top: .4rem;
  background: #f9f9f9;
  padding: .16rem .4rem;
  border-radius: .4rem;
}
.page-item .info .content .answer a.active {
  background: #5ec2b5;
  color: #FFF;
}
.page-item.left .info {
  margin-left: .6rem;
  width: 7.2rem;
}
.page-item.left .info .content {
  background: #FFF;
  position: relative;
}
.page-item.left .info .content:before {
  content: '';
  display: block;
  background: url(../../../assets/images/answer_left.png) no-repeat center / 100%;
  width: .8rem;
  height: .8rem;
  position: absolute;
  left: -0.4rem;
  top: -0.18rem;
  z-index: 1;
}
.page-item.left .info .content .answer {
  position: relative;
  z-index: 2;
}
.page-item.right {
  margin-left: 1.2rem;
  justify-content: flex-end;
}
.page-item.right .info {
  margin-right: .4rem;
}
.page-item.right .info .content {
  background: #5ec2b5;
  border-radius: .66rem;
  padding: 0 .5rem;
  height: 1rem;
  line-height: 1rem;
  position: relative;
}
.page-item.right .info .content:before {
  content: '';
  display: block;
  background: url(../../../assets/images/answer_right.png) no-repeat center / 100%;
  width: .8rem;
  height: .8rem;
  position: absolute;
  right: -0.2rem;
  top: -0.18rem;
  z-index: 1;
}
.page-item.right .info .content .answer {
  color: #FFF;
  font-size: 0.42666667rem;
  position: relative;
  z-index: 2;
}
.page-time {
  display: flex;
  justify-content: center;
  padding: .4rem 0;
}
.page-time span {
  color: #FFF;
  background: #d8d8d8;
  display: inline-block;
  padding: .1rem .4rem;
  border-radius: 1rem;
}
